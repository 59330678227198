var floatingLabels = function($options) {
  this.init = function($el) {
    this.floatingLabels = document.querySelectorAll($el);

    for(var $i = 0; $i < this.floatingLabels.length; $i++) {
      this.addListener(this.floatingLabels[$i]);
    }
  }

  this.addListener = function($el) {
    $el.oninput = function() {
      this.toggleClass($el);
    }.bind(this);
  }

  this.toggleClass = function($el) {
    if($el.value != '') {
      $el.classList.add('is-floating');
    } else {
      $el.classList.remove('is-floating');
    }
  }

  this.init($options.el);
}

new floatingLabels({
  el: '[data-floating-label]'
});