var carousel = function($options) {
  this.init = function($el) {
    try {
      if(!$el) throw 'No carousel defined';
    }

    catch($err) {
      return console.error($err);
    }

    this.el = document.querySelector($el);
    this.glide = this.createGlideInstance();

    return this;
  }

  this.createGlideInstance = function() {
    return new Glide(this.el, {
      type: 'carousel',
      startAt: 0,
      autoplay: 5000,
      animationDuration: 1000,
      perView: 6,
      gap: 64,
      breakpoints: {
        960: {
          perView: 5
        },
        800: {
          perView: 4
        },
        640: {
          perView: 3
        },
        480: {
          perView: 2
        }
      },
      hoverpause: true
    }).mount();
  }

  this.init($options.el);
}