var toggle = function($options) {
  this.init = function($el, $class) {
    try {
      if(!$el) throw 'No toggle defined';
    }

    catch($err) {
      return console.error($err);
    }

    this.toggle = document.querySelector($el);
    this.class = $class;

    this.addListener();

    return this;
  }

  this.addListener = function() {
    this.toggle.onclick = function(e) {
      var $id = this.toggle.href.substr(this.toggle.href.indexOf('#') + 1),
          $el = document.getElementById($id);

      this.toggleClass($el);
      return false;
    }.bind(this);
  }

  this.toggleClass = function($el) {
    if(!$el.classList.contains(this.class)) {
      $el.classList.add(this.class);
      this.toggle.classList.add(this.class);
    } else {
      $el.classList.remove(this.class);
      this.toggle.classList.remove(this.class);
    }
  }

  this.init($options.el, ($options.class ? $options.class : 'is-active'));
}

var navPrimary = new toggle({
  el: '[data-nav-mobile]'
});