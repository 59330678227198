var map = function($options) {
  this.init = function($el) {
    try {
      if(!$el) throw 'No carousel defined';
    }

    catch($err) {
      return console.error($err);
    }

    this.el = document.querySelector($el);
    this.bounds = new google.maps.LatLngBounds();
    this.locations = $options.locations;

    this.renderMap();

    return this;
  }

  this.renderMap = function() {
    this.createMapInstance();
    this.createMarkers();
  }

  this.createMapInstance = function() {
    this.map = new google.maps.Map(this.el, {
      zoom: 8,
      maxZoom: 18,
      mapTypeControl: false,
      styles: [{
        featureType: "poi",
        elementType: "labels",
        stylers: [{
          visibility: "off"
        }]
      }],
      center: {
        lat: 51.0523814, 
        lng: 3.7356854
      }
    });
  }

  this.createMarkers = function() {
    for(var $i = 0; $i < this.locations.length; $i++) {
      var $marker = this.createMarker(this.locations[$i]),
          $infowindow = this.createInfowindow(this.locations[$i]);

      this.addListener($marker, $infowindow);
    }

    this.map.fitBounds(this.bounds);
  }

  this.createMarker = function($location) {
    this.bounds.extend(new google.maps.LatLng($location.lat, $location.lng));

    return new google.maps.Marker({
      position: new google.maps.LatLng($location.lat, $location.lng), 
      title: $location.title,
      map: this.map
    });
  }

  this.createInfowindow = function($location) {
    return new google.maps.InfoWindow({
      content: `
      <div class="map-infowindow">
        <address class="map-infowindow-address">
          <p class="map-infowindow-title">` + $location.title + `</p>
          <p>
            ` + $location.streetAddress + `
            <br>
            ` + $location.postalCode + ` ` + $location.addressLocality + `
          </p>
        </address>
      </div>
      `
    });
  }

  this.addListener = function($marker, $infowindow) {
    $marker.addListener('click', function() {
      $infowindow.open(this.map, $marker);
    });
  }

  this.init($options.el);
}